div.dataTables_filter {
	float:right;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	margin-left:0;
	display:inline;
	padding:0;
}

.dataTables_scroll .dataTables_scrollHeadInner,
.dataTables_scroll .dataTables_scrollFootInner {
	margin:0 auto;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-image: none;
    background-color:#f5f5f5;
}

table.dataTable tr.group,
table.dataTable tr.group_primary,
table.dataTable tr.group_secondary {
	background-color: #EDEDED;
    font-weight:bold;
}
table.dataTable tr.group_secondary {
	background-color: rgba(#ECECEC, 0.3);
    font-weight:bold;
}

table.dataTable tr > th {
	font-weight: bold;
	font-size: 11px;
	text-transform: uppercase;
	color: #807f7f;
	background-color:#f5f5f5;
}

table.dataTable tr.group > th,
table.dataTable tr.group_priamry > th,
table.dataTable tr.group_secondary > th {
	border-bottom-width:2px;
}

table.dataTable.no-footer {
	border-bottom-color:#f4f4f4;
}
