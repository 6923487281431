.th-bg-gold {
	transition: 0.4s background-color;
	background-color:$thGold;
	color:$white;
	&-disabled {
		background-color:lighten($thGold, 25%);
	}
}

.th-bg-black {
	transition: 0.4s background-color;
	background-color:$iosBlack;
	color:$white;
	&-disabled {
		background-color:lighten($iosBlack, 25%);
	}
}

.th-bg-white {
	transition: 0.4s background-color;
	background-color:$iosWhite;
	color:$black;
	&-disabled {
		background-color:lighten($iosWhite, 25%);
	}
}

.th-bg-blue {
	transition: 0.4s background-color;
	background-color:$iosBlue;
	color:$white;
	&-disabled {
		background-color:lighten($iosBlue, 25%);
	}
}

.th-bg-brown {
	transition: 0.4s background-color;
	background-color:$iosBrown;
	color:$white;
	&-disabled {
		background-color:lighten($iosBrown, 25%);
	}
	
}

.th-bg-cyan {
	transition: 0.4s background-color;
	background-color:$iosCyan;
	color:$white;
	&-disabled {
		background-color:lighten($iosCyan, 25%);
	}
}

.th-bg-green {
	transition: 0.4s background-color;
	background-color:$iosGreen;
	color:$black;
	&-disabled {
		background-color:lighten($iosGreen, 25%);
	}
}

.th-bg-darkGray {
	transition: 0.4s background-color;
	background-color:$iosDarkGray;
	color:$black;
	&-disabled {
		background-color:lighten($iosDarkGray, 25%);
	}
}

.th-bg-gray {
	transition: 0.4s background-color;
	background-color:$iosGray;
	color:$black;
	&-disabled {
		background-color:lighten($iosGray, 25%);
	}
}

.th-bg-lightGray {
	transition: 0.4s background-color;
	background-color:$iosLightGray;
	color:$black;
	&-disabled {
		background-color:lighten($iosLightGray, 25%);
	}
}

.th-bg-magenta {
	transition: 0.4s background-color;
	background-color:$iosMagenta;
	color:$white;
	&-disabled {
		background-color:lighten($iosMagenta, 25%);
	}
}

.th-bg-orange {
	transition: 0.4s background-color;
	background-color:$iosOrange;
	color:$black;
	&-disabled {
		background-color:lighten($iosOrange, 25%);
	}
}

.th-bg-purple {
	transition: 0.4s background-color;
	background-color:$iosPurple;
	color:$white;
	&-disabled {
		background-color:lighten($iosPurple, 25%);
	}
}

.th-bg-red {
	transition: 0.4s background-color;
	background-color:$iosRed;
	color:$white;
	&-disabled {
		background-color:lighten($iosRed, 25%);
	}
}

.th-bg-yellow {
	transition: 0.4s background-color;
	background-color:$iosYellow;
	color:$black;
	&-disabled {
		background-color:lighten($iosYellow, 25%);
	}
}
