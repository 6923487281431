@import "fonts";
@import "variables";
@import "utilities";
@import "alerts";
@import "animations";
@import "backgrounds";
@import "dropdowns";
@import "text";
@import "lists";
@import "forms";
@import "modals";
@import "notifications";
@import "intro-box";
@import "btn-box";
@import "feature-box";
@import "btn-breadcrumb";
@import "pcm";
@import "datatables";
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

html {
    font-size: initial;
}

body {
    font-family: $primary-font-family;
    background-color: #ecf0f5;
    // font-weight:300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $primary-font-family;
}

a:hover,
a:focus {
    text-decoration: none;
}

.btn {
    transition: .2s all;
}

.wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.secondary-sidebar,
.skin-green .left-side {
    background-color: #d9e0e8;
    left: 230px;
}

.treeview>ul.treeview-menu {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.treeview-menu>li>a {
    font-size: 12px;
    padding: 5px 5px 5px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// Override side menu for fr_FR
@media (min-width: 768px) {

    .fr_FR.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right),
    .fr_FR.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
        width: 280px;
    }
}

.medium {
    width: 360px;

    +.content-wrapper {
        margin-left: 590px;

        .sidebar-collapse & {
            @media #{$over-small-displays} {
                margin-left: 360px;
            }
        }
    }
}

.large {
    width: 1054px;

    +.content-wrapper {
        margin-left: 1284px;

        .sidebar-collapse & {
            @media #{$over-small-displays} {
                margin-left: 1054px;
            }
        }
    }
}

.overlay-wrapper {
    position: relative;
}

.box>.overlay,
.overlay-wrapper>.overlay,
.box .overlay,
.overlay-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content-quick-links {
    position: relative;
    padding: 15px 15px 0 15px;
}

.lead {
    font-weight: normal;
}

.text-larger {
    font-size: 1.2em;
}

.text-largest {
    font-size: 2.4em;
}

.version {
    color: #CCCCCC;
    text-align: center;
    font-size: 11px;

    .sidebar-collapse & {
        display: none;
    }
}

.icheckbox_flat-green,
.iradio_flat-green {
    margin: 7px 10px 7px 0;
}

.nav-tabs-custom>.tab-content {
    padding: 0;
}

.widget-user .widget-user-header {
    height: 130px;
}

.widget-user .widget-user-image {
    top: 74px;
}

.box.widget-pricing.selected {
    border: 3px solid #00a65a;
    margin-top: -3px;
}

.widget-pricing {
    cursor: pointer;
}

.widget-pricing-title {
    font-weight: 400;
    margin: 0;
    padding: 0.5em 0 0;
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 55px;
    text-transform: uppercase;
}

.widget-pricing-priceunit {
    font-weight: bold;
    margin-bottom: 12px;
}

.widget-pricing-symbol {
    vertical-align: top;
    line-height: 2.2em;
    font-size: 18px;
}

.widget-pricing-number {
    font-size: 45px;
    line-height: 55px;
}

.widget-pricing-frequency {
    text-transform: uppercase;
}

// .small-profile {
//     cursor:pointer;
//     // position: absolute;
//     // top: 15px;
//     // right: 15px;
//     .small-profile-img {
//         margin: 0 auto;
//         width: 100px;
//         padding: 3px;
//         border: 3px solid #d2d6de;
//     }
//     .small-profile-username {
//         a {
//             text-decoration:none;
//             display:block;
//         }
//         text-align:right;
//         margin:0;
//         float:left;
//         display:inline-block;
//         line-height:20px;
//         padding-top:5px;
//         padding-right:10px;
//     }
// }

p.small-box-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

// Rotate the building icon 90 deg
.fa-building-o {
    transform: rotateZ(90deg) translateX(5px) translateY(2px);
}

.skin-green .sidebar a {
    color: #ffffff;
}

.skin-green .sidebar-menu>li {
    &.section-green {
        &:hover>a {
            background-color: #00a65a;
            border-left-color: #00a65a;
        }

        &.active>a {
            background-color: #00a65a;
            border-left-color: #00a65a;
        }
    }

    &.section-yellow {
        &:hover>a {
            background-color: #f39c12;
            border-left-color: #f39c12;
        }

        &.active>a {
            background-color: #f39c12;
            border-left-color: #f39c12;
        }
    }

    &.section-light-blue {
        &:hover>a {
            background-color: #3c8dbc;
            border-left-color: #3c8dbc;
        }

        &.active>a {
            background-color: #3c8dbc;
            border-left-color: #3c8dbc;
        }
    }

    &.section-teal {
        &:hover>a {
            background-color: #70afaf;
            border-left-color: #70afaf;
        }

        &.active>a {
            background-color: #70afaf;
            border-left-color: #70afaf;
        }
    }

    &.section-maroon {
        &:hover>a {
            background-color: #d81b60;
            border-left-color: #d81b60;
        }

        &.active>a {
            background-color: #d81b60;
            border-left-color: #d81b60;
        }
    }

    &.section-purple {
        &:hover>a {
            background-color: #605ca8;
            border-left-color: #605ca8;
        }

        &.active>a {
            background-color: #605ca8;
            border-left-color: #605ca8;
        }
    }

    &.section-aqua {
        &:hover>a {
            background-color: #00c0ef;
            border-left-color: #00c0ef;
        }

        &.active>a {
            background-color: #00c0ef;
            border-left-color: #00c0ef;
        }
    }

    &.section-navy {
        &:hover>a {
            background-color: #001f3f;
            border-left-color: #001f3f;
        }

        &.active>a {
            background-color: #001f3f;
            border-left-color: #001f3f;
        }
    }

    &.section-gray-active {
        &:hover>a {
            background-color: #b5bbc8;
            border-left-color: #b5bbc8;
        }

        &.active>a {
            background-color: #b5bbc8;
            border-left-color: #b5bbc8;
        }
    }

}

.absolute-top {
    position: absolute;
    top: 0;
}

.absolute-bottom {
    position: absolute;
    bottom: 0;
}

.absolute-left {
    position: absolute;
    left: 0;
}

.absolute-right {
    position: absolute;
    right: 0;
}

.absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.absolute-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.absolute-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.alert {
    a.btn {
        color: #333;
        text-decoration: none;
        margin-left: 12px;
    }
}

.input-group-btn>.btn {
    line-height: 1.9;
}

[class*="bg-"].disabled {
    opacity: .65;
    filter: alpha(opacity=65);
}

[v-cloak] {
    display: none;
}

.nav>li>a {
    cursor: pointer;
}

.navbar-btn {
    margin-top: 0;
    margin-bottom: 0;
}

.nav-stacked>li>a {
    display:flex;
    align-items:center;
    justify-content:space-between;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    z-index: 1500;
}

// @media only screen and (max-width: 767px) {
.scroll-x {
    overflow-x: scroll;
}

.pagination {
    width: max-content;
}

// }

table.table {
    // border:1px solid #f9f9f9;
    border-radius: 3px;
}

table.table>thead>tr>th,
table.table>tbody>tr>th,
table.table>tfoot>tr>th {
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    color: #807f7f;
    background-color: #f7f7f7;
}

table.table>thead>tr>th,
table.table>thead>tr>td,
table.table>tbody>tr>th,
table.table>tbody>tr>td,
table.table>tfoot>tr>th,
table.table>tfoot>tr>td {
    vertical-align: middle;
}

.image-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 767px) {
    .vue-daterange-picker .reportrange-text {
        font-size: 14px !important;
        line-height: 2.5 !important;
    }

    .full-width-xs {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .hidden-sm {
        display: none;
    }
}

.vue-daterange-picker .form-control {
    line-height:1.8;
}

.sidebar-menu li > a > .fa-angle-down,
.sidebar-menu li > a > .pull-right-container > .fa-angle-down {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  transition: transform 0.5s ease;
}
.sidebar-menu li > a > .fa-angle-down {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.sidebar-menu .menu-open > a > .fa-angle-down,
.sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-down {
  transform: rotate(-180deg);
}