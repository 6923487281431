.th__dropdownMenu {
	border-radius:0;
	border:none;
	> li {
		>a {
			padding:6px 20px;
		}
	}

	&.skin-green,
	&.skin-purple,
	&.skin-red,
	&.skin-yellow {
		color: $white;
		> li {
			>a {
				color:$white;
				&:hover {
					background-color: rgba($black, 0.1);
				}
			}
		}
	}

	&.skin-black {
		color: #333333;
		> li {
			>a {
				color:#333333;
			}
		}
	}

	&.skin-green {
		background: $brand-success!important;
		border-color:  $brand-success!important;
	}
	&.skin-black {
		background: #FFFFFF!important;
		border-color:  #FFFFFF!important;
	}
	&.skin-purple {
		background: #605ca8!important;
		border-color:  #605ca8!important;
	}
	&.skin-red {
		background: #dd4b39!important;
		border-color:  #dd4b39!important;
	}
	&.skin-yellow {
		background:$brand-warning!important;
		border-color: $brand-warning!important;
	}
}