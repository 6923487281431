.feature-box {
	&:hover {
		box-shadow: inset 0px 0px 45px rgba(170, 170, 170, 0.2);
	}

	margin-bottom:20px;
	transition: 0.5s all;
	border: 1px solid #DDD;
	background-color: #f4f4f4;
	display: block;
	border-radius: 3px;
	position: relative;
	.colour-overlay {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index:1;
		opacity:0.1;
	}
	h3 {
		font-weight:600;
		color:#777;
		position: relative;
		z-index:2;
		margin-top:15px;
	}
	p {
		color:#777;
		text-transform:uppercase;
		font-size:0.8em;
		position: relative;
		z-index:2;
	}
	div.icon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index:1;
		color:#777;
		font-size:72px;
		opacity:0.1;
	}
	.inner {
		padding: 10px;
	}
}

.feature-box-small {
	&:hover {
		box-shadow: inset 0px 0px 45px rgba(170, 170, 170, 0.2);
	}

	margin-bottom:20px;
	transition: 0.5s all;
	border: 1px solid #DDD;
	background-color: #f4f4f4;
	display: block;
	border-radius: 3px;
	position: relative;
	.colour-overlay {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index:1;
		opacity:0.1;
	}
	h4 {
		font-weight: 600;
		color: #777;
		position: relative;
		z-index: 2;
		margin-top: 6px;
		margin-bottom: 6px;
		font-size: 16px;
	}
	p {
		color:#777;
		text-transform:uppercase;
		font-size:0.7em;
		position: relative;
		z-index:2;
		margin-bottom:6px;
	}
	.inner {
		padding: 6px;
	}
}
