@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.th__notification {
  padding: 10px;
  margin: 5px;

  font-size: 14px;
  position: relative;

  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
  border-radius:3px;

  // &:after {
  //   position:absolute;
  //   top:12px;
  //   right:15px;
  //   font-family:FontAwesome;
  //   content: "\F00D";
  //   color:#EFEFEF;
  // }

  &.warning {
    background: $brand-warning;
    border-left-color: darken($brand-warning, 10%);
  }

  &.error {
    background: $brand-danger;
    border-left-color: darken($brand-danger, 10%);
  }

  &.success {
    background: $brand-success;
    border-left-color: darken($brand-success, 10%);  }
}