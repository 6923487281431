$brand-primary:         #428bca; // #337ab7
$brand-success:         #00a65a;
$brand-info:            #6aa3d5;
$brand-warning:         #fcf2e3;
$brand-danger:          #d9534f;

$white: #ffffff;
$black: #333333;

$iosBlack: rgb(0,0,0);
$iosWhite: rgb(255,255,255);
$iosBlue: rgb(0, 0, 255);
$iosBrown: rgb(153, 102, 51);
$iosCyan: rgb(0, 255, 255);
$iosDarkGray: rgb(84,84,84);
$iosGray: rgb(127,127,127);
$iosLightGray: rgb(170,170,170);
$iosMagenta: rgb(255, 0, 255);
$iosOrange: rgb(255, 127, 0);
$iosPurple: rgb(127, 0, 127);
$iosRed: rgb(255, 0, 0);
$iosYellow: rgb(255, 255, 0);

$iosGreen: rgb(76, 217, 100);
$iosTeal: rgb(90, 200, 250);

$tillYellow: rgb(255,255,200);
$tillBlue: rgb(0,101,156);

$thGold: #dabf2b;

// $primary-font-family: 'Lato', Helvetica, Arial, sans-serif;
$primary-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
$secondary-font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;

// Responsive snap points
$upto-xlarge-displays: "only screen and (max-width: 1499px)";
$over-xlarge-displays: "only screen and (min-width: 1500px)";

$upto-large-displays: "only screen and (max-width: 1199px)";
$over-large-displays: "only screen and (min-width: 1200px)";

$upto-medium-displays: "only screen and (max-width: 991px)";
$over-medium-displays: "only screen and (min-width: 992px)";

$upto-small-displays: "only screen and (max-width: 767px)";
$over-small-displays: "only screen and (min-width: 768px)";

$upto-xsmall-displays: "only screen and (max-width: 479px)";
$over-xsmall-displays: "only screen and (min-width: 480px)";

// iPhone media queries
$iphone-4-landscape: "only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape)";
$iphone-4-portrait: "only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait)";

$iphone-5-landscape: "only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape)";
$iphone-5-portrait: "only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait)";

$iphone-6-landscape: "only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape)";
$iphone-6-portrait: "only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait)";

$iphone-6plus-landscape: "only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape)";
$iphone-6plus-portrait: "only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait)";
