/*
 * Component: Small Box
 * --------------------
 */
.intro-box {
	border-radius: 2px;
	position: relative;
	display: block;
	margin-bottom: 20px;
	text-align:center;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
	// content wrapper
	> .inner {
		padding: 10px;
	}

	> a {
		display:block;
		color:white;
	}

	> .intro-box-footer {
		position: relative;
		text-align: center;
		padding: 3px 0;
		color: #fff;
		color: rgba(255, 255, 255, 0.8);
		display: block;
		z-index: 10;
		background: rgba(0, 0, 0, 0.1);
		text-decoration: none;
		&:hover {
			color: #fff;
			background: rgba(0, 0, 0, 0.15);
		}
	}

	h3 {
		font-size: 38px;
		font-weight: bold;
		margin: 0 0 10px 0;
		white-space: nowrap;
		padding: 0;

	}

	p {
		font-size: 12px;
    	text-transform: uppercase;
    	font-weight: bold;
    	color:white;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		> small {
			display: block;
			color: #f9f9f9;
			font-size: 13px;
			margin-top: 5px;
		}
	}

	h3, p {
		z-index: 5;
	}

	// the icon
	.icon {
		font-size:36px;
		color: rgba(255, 255, 255, 0.9);
	}

	// Small box hover state
	&:hover {
		text-decoration: none;
		color: #f9f9f9;
		// Animate icons on small box hover
		.icon {
			// font-size: 95px;
		}
	}
}

@media #{$upto-xsmall-displays} {
	// No need for icons on very small devices
	.intro-box {
		text-align: center;
		// .icon {
		// 	display: none;
		// }
		p {
			font-size: 12px;
		}
	}
}
