.pcm-box {
	min-height: 120px;
	height: 120px;
	white-space: normal;
	text-align: center;
	// color: #FFFFFF;

	&.pcm-box-disabled {
		box-shadow: inset 0 0 0 250px rgba(255,255,255,0.6);

		>.inner {
			color: #888888;
		}

		>.pcm-box__actions a {
			color: #888888;
		}
	}

	>.inner {
		position: relative;
		top: 50%;
		display: block;
		// color:white;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	>.small-box-footer,
	>.pcm-box__actions {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}

	>.pcm-box__actions {
		background-color: rgba(0, 0, 0, 0.1);

		a {
			color: $white;
		}
	}

	&.th-bg-gray>.pcm-box__actions a,
	&.th-bg-lightGray>.pcm-box__actions a,
	&.th-bg-darkGray>.pcm-box__actions a,
	&.th-bg-white>.pcm-box__actions a,
	&.th-bg-yellow>.pcm-box__actions a,
	&.th-bg-orange>.pcm-box__actions a {
		color: $iosBlack;
	}

	.pcm-box-icon {
		font-size: 32px;
		padding-bottom: 8px;
	}

	.sortable-ghost & {
		opacity: 0.5;
	}

	&.box-outline,
	.sortable-ghost & {
		background-color: transparent !important;
		box-shadow: none;
		transition: 0.4s all;

		&.product {
			border: 3px dashed #3c8dbc;
			color: #3c8dbc !important;

			>.pcm-box__actions {
				a {
					color: #3c8dbc !important;
				}
			}
		}

		&.category {
			border: 3px dashed #00a65a;
			color: #00a65a !important;

			>.pcm-box__actions {
				a {
					color: #00a65a !important;
				}
			}
		}
	}

	>.pcm-box__loader {
		font-size: 32px;
		opacity: 0.5;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		display: flex;
	}
}

// .btn-box {
// border-radius:2px;
// 	margin-bottom:20px;
// 	box-shadow:0 1px 1px rgba(0, 0, 0, 0.1);


// 	> div {
// 		min-height:100px;
// 		height:100px;
// 		// position: relative;
// 		// top: 50%;
// 		// -webkit-transform: translateY(-50%);
// 		// -ms-transform: translateY(-50%);
// 		// transform: translateY(-50%);
// 	}
// 	.btn-box-footer {
// 		position: relative;
// 		text-align: center;
// 		padding: 3px 0;
// 		color: rgba(255,255,255,0.8);
// 		display: block;
// 		z-index: 10;
// 		background: rgba(0,0,0,0.1);
// 		text-decoration: none;
// 	}
// 	.btn-box-icon {
// 		font-size:32px;
// 		padding-bottom:8px;
// 	}

// }