.padding {
	padding: 10px;
}

.img-circle {
	clip-path: circle();
	object-fit: cover;
}

.vertical-align-middle {
	display: flex;
	align-items: center;
}

.d-flex {
	display:flex;
}

.flex-1	{ flex: 1 1 0% };
.flex-auto	{ flex: 1 1 auto };
.flex-initial { flex: 0 1 auto };
.flex-none { flex: none; };