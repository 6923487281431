.text-gold {
	color:#29f312;
}

.text-white {
	color:#ffffff;
}
.text-black {
	color:#333333;
}


.text-gold-disabled {
	color:lighten(#29f312, 25%);
}

.original-price { text-decoration: line-through; }