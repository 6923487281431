ol.sortable,
ol.sortable ol {
	#tree & {
		margin:0 -15px 20px;
		ol {
			margin:0 0 0 20px;
		}
	}
	#ipad & {
		position: absolute;
		ol.sortable__subCats {
			left:0;
			top:110px;
		}
		ol.sortable__products {
			left:0;
			top:220px;
		}
	}
	padding:0;
	list-style-type:none;
}

.sortable__item {
	border-left:5px solid #c3c3c3;
	display:block;
	position: relative;
	#ipad & {
		margin:5px;
		display:block;
		float:left;
		width:150px;
		height:100px;
		background-color: $iosBlue;
		.sortable__item.category {
			background-color: $iosTeal;
		}
		.sortable__item.product {
			background-color: $tillYellow;
			color:#333;
		}
		color:white;
	}
	&.category {
		&.editing {
			border-left-color:#00a65a;
		}
	}
	&.product {
		&.editing {
			border-left-color:#367FA7;
		}
	}
	&.mjs-nestedSortable-collapsed > ol {
	    display: none;
	}
	&.mjs-nestedSortable-branch > div > .disclose {
	    display: inline-block;
	    content:"\f055";
	}
	&.mjs-nestedSortable-collapsed > div > .disclose:before {
	    content:"\f055";
	}
	&.mjs-nestedSortable-expanded > div > .disclose:before  {
	    content:"\f056";
	}
}

.sortable__placeholder {
	outline:1px dashed #4183C4;
}

.sortable__addNewPlaceholder {
	border:1px dashed #4183C4;
	line-height: 30px;
    padding: 0 8px;
}

.sortable__handle {
	#tree & {
		border-top:1px solid #c3c3c3;
	}
	padding:10px;
	margin:0;
	cursor:pointer;
	background:transparent;
	position:relative;
	transition:0.25s all;
	font-size:12px;
	line-height:21px;
	.editing > & {
		box-shadow:none;
	}
	.product.editing > & {
		border-color:#367FA7;
		background:#9ec8e0;
	}
	.category.editing > & {
		border-color:#00a65a;
		background:#80d3ad;
	}
	.product:hover > & {
		background-color:#dbd1e8;
	}
	.category:hover > & {
		background-color:#d2d2d2;
	}
	.notactive > & {
		color:#8c8c8c;
	}
	.active > & {
		color:inherit;
	}
}

.sortable__buttons {
	position:absolute;
	top:10px;
	right:10px;
	a {
		text-decoration:none;
	}
}