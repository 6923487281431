.swal2-popup {
	font-size:1.4rem;

}

.th-modal-mask {
	overflow: auto;
	position: fixed;
	z-index: 1050;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#004827, .4);
	transition: opacity .3s ease;
}

.th-modal-container {
    width: 300px;
    margin: 40px auto;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .2);

    // padding: 20px 30px;
    // background-color: #fff;
    // border-radius: 2px;
    transition: all .3s ease;
    // font-family: Helvetica, Arial, sans-serif;
}

.th-modal-close {
	overflow: visible;
    cursor: pointer;
    -webkit-appearance: none;
    display: block;
    outline: none;
    border:0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 7px;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    // padding: 0 0 18px 10px;
    color: #333;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
    transition: all .3s ease;
    &:hover {
    	opacity:1;
    }
}



.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .th-modal-container,
.modal-leave-active .th-modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.popupWindow {
	position: relative;
	max-width: 90%;
	width:600px;
	margin: 1em auto;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border-radius: 4px;
	box-shadow: 0 3px 9px rgba(0,0,0,.5);
	outline:0;
	display: block;
}

.popupWindow--sm, .th-modal-container--sm {
	@media (min-width:992px)
	{
		width:300px;
	}
}

.popupWindow--md, .th-modal-container, .th-modal-container--md {
	width:600px;
}

.popupWindow--lg, .th-modal-container--lg {
	@media (min-width:992px)
	{
		width:900px;
	}

	width:1280px;
}

.popupWindow .mfp-close {
	margin-top:5px;
}

.modal-header {
	border-radius:4px 4px  0 0;
}

.modal-footer {
	border-radius:0 0 4px 4px;
}

.modal-backdrop {
	background: transparent;
}

.modal-dialog {
	margin:0;
	width:auto;
}

.modal-content {
	border-radius:4px;
	box-shadow:none;
	border:none;
	background-clip: initial;

}

.mfp-bg {
	background:#004827;
}

/* at start */
.my-mfp-slide-bottom .popupWindow {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .popupWindow {
	opacity: 1;
	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	transform: translateY(0) perspective( 600px ) rotateX( 0 );
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .popupWindow {
	opacity: 0;

	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;

	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.4;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}

.popupWindow__footer .btn+.btn {
	margin-left:10px;
}
