// Use this file for overwriting bootstrap defaults

$brand-primary:         		#428bca; // #337ab7
$brand-success:         		#00a65a;
$brand-info:            		#6aa3d5;
$brand-warning:         		#f39c12;
$brand-danger:          		#d9534f;

$btn-default-color:             #414142 !default;
$btn-default-bg:                #f4f4f4 !default;
$btn-default-border:            #dddddd !default;

// table > tbody > tr > td
$table-border-color:			#f4f4f4;
$alert-padding-small: 			7px 12px;
$alert-height-small: 			34px;
$alert-info-border: 			#22add0;

