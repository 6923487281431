// @font-face {
//     font-family: 'AvenirNext';
//     src: url('~/fonts/avenir-next-regular.eot');
//     src: url('~/fonts/avenir-next-regular.eot') format('embedded-opentype'),
//          url('~/fonts/avenir-next-regular.woff') format('woff'),
//          url('~/fonts/avenir-next-regular.ttf') format('truetype'),
//          url('~/fonts/avenir-next-regular.svg#AvenirNextRegular') format('svg');
//     font-weight:normal;
// }

// @font-face {
//     font-family: 'AvenirNext';
//     src: url('~/fonts/avenir-next-bold.eot');
//     src: url('~/fonts/avenir-next-bold.eot') format('embedded-opentype'),
//          url('~/fonts/avenir-next-bold.woff') format('woff'),
//          url('~/fonts/avenir-next-bold.ttf') format('truetype'),
//          url('~/fonts/avenir-next-bold.svg#AvenirNextRegular') format('svg');
//     font-weight:bold;
// }

// @font-face {
//     font-family: 'AvenirNext';
//     src: url('~/fonts/avenir-next-thin.eot');
//     src: url('~/fonts/avenir-next-thin.eot') format('embedded-opentype'),
//          url('~/fonts/avenir-next-thin.woff') format('woff'),
//          url('~/fonts/avenir-next-thin.ttf') format('truetype'),
//          url('~/fonts/avenir-next-thin.svg#AvenirNextRegular') format('svg');
//     font-weight:100;
// }

// @font-face {
//     font-family: 'AvenirNext Italic';
//     src: url('~/fonts/avenir-next-italic.eot');
//     src: url('~/fonts/avenir-next-italic.eot') format('embedded-opentype'),
//          url('~/fonts/avenir-next-italic.woff') format('woff'),
//          url('~/fonts/avenir-next-italic.ttf') format('truetype'),
//          url('~/fonts/avenir-next-italic.svg#AvenirNextRegular') format('svg');
//     font-style:italic;
// }

    /*
    Flaticon icon font: Flaticon
    Creation date: 24/01/2020 15:46
    */

@font-face {
  font-family: "Flaticon";
  src: url("~/fonts/Flaticon.eot");
  src: url("~/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("~/fonts/Flaticon.woff2") format("woff2"),
       url("~/fonts/Flaticon.woff") format("woff"),
       url("~/fonts/Flaticon.ttf") format("truetype"),
       url("~/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("~/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-beer:before { content: "\f100"; }
.flaticon-dinner:before { content: "\f101"; }
.flaticon-rfid:before { content: "\f102"; }
.flaticon-beer-1:before { content: "\f103"; }
.flaticon-payment:before { content: "\f104"; }
.flaticon-credit-card:before { content: "\f105"; }
    
    $font-Flaticon-beer: "\f100";
    $font-Flaticon-dinner: "\f101";
    $font-Flaticon-rfid: "\f102";
    $font-Flaticon-beer-1: "\f103";
    $font-Flaticon-payment: "\f104";
    $font-Flaticon-credit-card: "\f105";